import $ from "jquery";

const moveUpFieldForm = () => {
  $("form").on("click", ".move_up", function (event) {
    let el = $(this).closest("tr");
    el.insertBefore(el.prev())

    $(el).parent().find("> tr > td > input.order_field").each(function(index) {
      $(this).val(index + 1);
    });

    return event.preventDefault();
  });
};

const moveDownFieldForm = () => {
  $("form").on("click", ".move_down", function (event) {
    let el = $(this).closest("tr");
    el.insertAfter(el.next());

    $(el).parent().find("> tr > td > input.order_field").each(function(index) {
      $(this).val(index + 1);
    });

    return event.preventDefault();
  });
};

export { moveUpFieldForm, moveDownFieldForm };
